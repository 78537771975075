import React, { useCallback, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import type { UnpackResponse } from "../../../api/src/lib";
import type { RebalancesController } from "../../../api/src/rebalances/rebalances.controller";
import Content from "../components/Content";
import SubmitButton from "../components/SubmitButton";
import { useAuthenticatedMutation } from "../lib/api";

const ManualRebalanceGenerator = () => {
  const uploadRef = useRef<HTMLInputElement>(null);
  const [request, setRequest] = useState<string>();
  const [result, setResult] = useState<object>();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const uploadRebalanceRequest = useAuthenticatedMutation<
    UnpackResponse<RebalancesController["manualRebalanceGeneration"]>
  >("/rebalances/manual", {
    method: "POST",
    body: request,
  });

  const onSubmit = useCallback(
    async (ev: React.FormEvent) => {
      ev.preventDefault();
      setIsSubmitting(true);
      setResult(undefined);

      try {
        const response = await uploadRebalanceRequest();
        setResult(response.data);
      } catch {
        setResult({ error: "An error occurred" });
      } finally {
        setIsSubmitting(false);

        if (uploadRef.current !== null) {
          uploadRef.current.value = "";
        }
      }
    },
    [uploadRebalanceRequest],
  );

  const onSelectFile = (ev: React.ChangeEvent<HTMLInputElement>) => {
    if (ev.target.files && ev.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setRequest(reader.result?.toString() || ""),
      );
      reader.readAsText(ev.target.files[0]);
    }
  };

  return (
    <Content>
      <h1>Manual Rebalance Generation</h1>
      <Form onSubmit={onSubmit}>
        <Form.Group controlId="form-file" className="mb-3">
          <Form.Label>Rebalance File</Form.Label>
          <Form.Control
            type="file"
            ref={uploadRef}
            onChange={onSelectFile}
            accept="application/json"
            required
          />
        </Form.Group>
        <SubmitButton className="mb-3" isSubmitting={isSubmitting} />
      </Form>
      <h2>Result</h2>
      <pre>
        {typeof result === "undefined" ? (
          <em>Empty</em>
        ) : (
          JSON.stringify(result, null, 2)
        )}
      </pre>
    </Content>
  );
};

export default ManualRebalanceGenerator;
